import Cookies from 'js-cookie'

export function setUserId (user_id) {
    Cookies.set('lego_user_id', user_id)
}
export function getUserId () {
    return Cookies.get('lego_user_id')
}
export function removeUserId () {
    Cookies.remove('lego_user_id')
}