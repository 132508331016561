import Vue from 'vue'
import VueRouter from 'vue-router'

import { getUserId } from '../utils/store'
Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/upload',
        meta:{
            requiresAuth:true
        }
    },
    {
        path:'/login',
        name:'/login',
        component: () => import('@/components/Login'),
        meta:{
            requiresAuth:true
        }
    },
    {
        path:'/home',
        name:'Home',
        component: () => import('@/views/Home.vue'),
        meta:{
            requiresAuth:true
        },
        children:[
            {
                path:'/upload',
                name:'Upload',
                component: () => import('@/components/Upload'),
                meta:{
                    requiresAuth:true
                }
            },
            {
                path:'/videos',
                name:'Videos',
                component: () => import('@/components/MyVideos'),
             
            }
        ]
    }
]

const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        let userId = getUserId()
        if (to.meta.requiresAuth && !userId) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router
